import { lazy, Suspense, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isUserAuthorized } from "./store/selectors";
import { checkToken, loadInitialData } from "./actions/authActions";
import { Loading } from "./layout/utils/AppLoading";

const MainContainer = lazy(() => import("./containers/MainContainer"));
const AuthContainer = lazy(() => import("./containers/AuthContainer"));

const App = () => {

  const dispatch = useDispatch();

  const { auth } = useSelector(isUserAuthorized) || {};

  const fetchInitialData = useCallback(() => {
    dispatch(loadInitialData());
  }, [dispatch]);

  const checkPersistency = useCallback(() => {
    dispatch(checkToken());
  }, [dispatch]);

  useEffect(() => {
    auth ? fetchInitialData() : checkPersistency();
  }, [auth, fetchInitialData, checkPersistency]);

  return (
    <Suspense fallback={<Loading centered />}>
      {
        auth
          ? <MainContainer />
          : <AuthContainer />
      }
    </Suspense>
  );
};

export default App;