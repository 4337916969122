/* BASIC APP CONSTANTS */
export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const APP_COOKIE_NAME = 'APP_COOKIE_NAME';
export const USER_COOKIE_NAME = 'USER_COOKIE_NAME';
export const PASS_COOKIE_NAME = 'PASS_COOKIE_NAME';
export const USER_ID_COOKIE_NAME = 'USER_ID_COOKIE_NAME';
export const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE';
export const SET_SIDE_MENU_STATE = 'SET_SIDE_MENU_STATE';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
export const SHOW_ACTION_MENU = 'SHOW_ACTION_MENU';
export const HIDE_ACTION_MENU = 'HIDE_ACTION_MENU';
export const SHOW_BOTTOM_TOOLBAR = 'SHOW_BOTTOM_TOOLBAR';
export const HIDE_BOTTOM_TOOLBAR = 'HIDE_BOTTOM_TOOLBAR';
export const SET_USER_LIST = 'SET_USER_LIST';
export const SET_PROFILE = 'SET_PROFILE';

/* <---- BASIC APP CONSTANTS ----> */

/* ESTABLISHMENT */
export const SET_ESTABLISHMENT_DATA = 'SET_ESTABLISHMENT_DATA';
export const SET_ESTABLISHMENT_INFO = 'SET_ESTABLISHMENT_INFO';
export const SET_ESTABLISHMENT_SCHEDULED = 'SET_ESTABLISHMENT_SCHEDULED';
export const SET_ESTABLISHMENT_SERVICES = 'SET_ESTABLISHMENT_SERVICES';
export const SET_ESTABLISHMENT_ACTIVITIES = 'SET_ESTABLISHMENT_ACTIVITIES';
export const SET_ESTABLISHMENT_ZONE = 'SET_ESTABLISHMENT_ZONE';  
export const SET_ESTABLISHMENT_RESTAURANTS = 'SET_ESTABLISHMENT_RESTAURANTS';
export const SET_ESTABLISHMENT_MENUS = 'SET_ESTABLISHMENT_MENUS';
export const SET_ESTABLISHMENT_TPVS = 'SET_ESTABLISHMENT_TPVS';
export const SET_ESTABLISHMENT_HOMEPAGE = 'SET_ESTABLISHMENT_HOMEPAGE';

export const UPDATE_HOMEPAGE = 'UPDATE_HOMEPAGE';

export const ADD_RESTAURANT = 'ADD_RESTAURANT';
export const UPDATE_RESTAURANTS = 'UPDATE_RESTAURANTS';
export const ADD_FILE_MENU = 'ADD_FILE_MENU';
export const UPDATE_FILE_MENU = 'UPDATE_FILE_MENU';
export const DELETE_FILE_MENU = 'DELETE_FILE_MENU';
export const ADD_VW_MENU = 'ADD_VW_MENU';
export const DELETE_VW_MENU = 'DELETE_VW_MENU';

export const UPDATE_ESTABLISHMENT_INFO = 'UPDATE_ESTABLISHMENT_INFO';
export const UPDATE_ESTABLISHMENT_DATA = 'UPDATE_ESTABLISHMENT_DATA';

export const ADD_GALLERY_IMAGES = 'ADD_GALLERY_IMAGES';
export const SET_ESTABLISHMENT_GALLERY = 'SET_ESTABLISHMENT_GALLERY';
/* <---- ESTABLISHMENT ----> */

/* USERS */
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const OPEN_USERS_FORM = 'OPEN_USERS_FORM';
export const CLOSE_USERS_FORM = 'CLOSE_USERS_FORM';
export const EDIT_SELECTED_USER = 'EDIT_SELECTED_USER';
export const USERS_FORM_TOOGLE_LOADING = 'USERS_FORM_TOOGLE_LOADING';
/* <---- USERS ----> */

/* ROUTES */
export const SET_ALL_ROUTES = 'SET_ALL_ROUTES';
export const UPDATE_ROUTE = 'UPDATE_ROUTE';
export const ADD_ROUTE = 'ADD_ROUTE';

export const SET_ALL_PAGES = 'SET_ALL_PAGES';
export const ADD_PAGE = 'ADD_PAGE';
export const UPDATE_PAGE = 'UPDATE_PAGE';
/* <---- ROUTES ----> */

export const SET_ESTABLISHMENT_COLOR = 'SET_ESTABLISHMENT_COLOR';

export const SET_ACTIVITIES_LIST_DRAWER = 'OPEN_ACTIVITIES_LIST';
export const SET_SHOW_NEW_PAGE_FORM = 'SET_SHOW_NEW_PAGE_FORM';
export const SHOW_NEW_PAGE_FORM = 'SHOW_NEW_PAGE_FORM';

export const ADD_SCH_ACTIVITY = 'ADD_SCH_ACTIVITY';
export const UPDATE_SCH_ACTIVITY = 'UPDATE_SCH_ACTIVITY';
export const DELETE_SCH_ACTIVITY = 'DELETE_SCH_ACTIVITY';

export const DELETE_SERVICES_BY_ZONE = 'DELETE_SERVICES_BY_ZONE';
export const DELETE_SCH_ACTIVITY_BY_ZONE = 'DELETE_SCH_ACTIVITY_BY_ZONE';

/* STATIC TEXT TRANSLATION */

export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const UPDATE_TRANSLATION = 'UPDATE_TRANSLATION';
