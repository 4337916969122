import { keyBy } from "lodash";
import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";

export const messageType = {
	REQUEST : 'REQUEST',
	RESPONSE : 'RESPONSE'
}

export const getActiveChats = async() => {
	return axios.get(API.chat.activechats)
	.then(({data}) => {
		data = data.map((chat) => {
			chat.to = getLastSocketId(chat.messages);
			chat.pendent = chat.messages.filter(message => message.type === messageType.REQUEST && !message.readed).length;
			return chat;
		})
		return keyBy(data, '_id');
	})
	.catch(err => {
		return [];
	})
}

export const fetchSessionById = async(sessionId) => {
	return axios.get(API.chat.sessionById(sessionId))
	.then(({data}) => {
		data.to = getLastSocketId(data.messages);
		return data;
	})
	.catch(err => {
  	console.log("🛠️ ~ file: chatActions.js ~ line 31 ~ fetchSessionById ~ err", err);
		return null;
	})
}

const getLastSocketId = (messages) => {
	if(messages.length === 0) {
		return null;
	}
	return messages[messages.length - 1].from;
}
  
export const setChatMessageReaded = async(messageId) => {
	return axios.get(API.chat.markMessageReaded(messageId))
	.catch(err => {
  	console.log("🛠️ ~ file: chatActions.js ~ line 47 ~ setChatMessageReaded ~ err", err);
	})
}
