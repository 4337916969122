import React, { useContext, useEffect, useState, useCallback } from 'react';
import { getActiveChats, fetchSessionById, setChatMessageReaded, messageType } from '../../actions/chatActions';
import socketIOClient from "socket.io-client";
import { BASE_URL } from '../../connection/ConnectionHandler';
import { useSelector } from 'react-redux';
import { getEstablishmentId } from '../../store/selectors';

const ChatContext = React.createContext({});

export const useChat = () => {
	const context = useContext(ChatContext);

	return context
}

export const ChatProvider = ({children}) => {
	const [loading, setLoading] = useState(true);
	const [socket, setSocket] = useState();
	const [activeChats, setActiveChats] = useState({});
	const [selectedChatID, setSelectedChat] = useState();
	const establishmentId = useSelector(getEstablishmentId)

	const enableSocket = useCallback(() => {
		if(establishmentId){
			const socketURL = `${BASE_URL}/${establishmentId}?type=CMS`;
			const io = socketIOClient(socketURL);
			setSocket(io);
		}
	},[establishmentId])

	const getInitialChats = useCallback(async () => {
		setLoading(true);
		const act = await getActiveChats();
		setActiveChats(act);
		setLoading(false)
	},[])

	// socket side effects
	useEffect(() => {
		if(socket){
			socket.on('response-failed', (err) => {
				console.log(err);
			})

			socket.on('response-success', (data) => {
				const { storedMessage } = data;
				const key = storedMessage.sessionId;
				setActiveChats(prev => ({
					...prev,
					[key] : {
						...prev[key],
						messages : prev[key].messages.concat(storedMessage),
					}
				}))
			})

			socket.on('receive-request', async(data) => {
				const key = data.sessionId;
				if(!activeChats[key]){
					// new message from new chat
					const newChatSession = await fetchSessionById(key);
					newChatSession.pendent = newChatSession.messages.filter(message => message.type === messageType.REQUEST && !message.readed);
					setActiveChats(prev => ({
						...prev,
						[key] : newChatSession
					}))
					return;
				}

				// new message from existing chat
				setActiveChats(prev => ({
					...prev,
					[key] : {
						...prev[key],
						messages : prev[key].messages.concat(data),
						to : data.from,
						pendent : prev[key].pendent + 1
					}
				}))
			})
		}

		return () => {
			if(socket){	
				socket.disconnect();
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[socket]);

	useEffect(() => {
		enableSocket();
		getInitialChats();
	},[enableSocket, getInitialChats])

	const sendMessage = async(sessionId, msg) => {
		const to = activeChats[sessionId].to;

		const message = {
			sessionId : sessionId,
			msg,
			type : "RESPONSE"
		}

		socket.emit("send-response", {message : message, to});
	}

	const getChatBySessionId = sessionId => {
		return activeChats[sessionId];
	}

	const getSelectedChat = () => {
		return getChatBySessionId(selectedChatID);
	}

	const setSelectedChatID = (chatID) => {
		if(chatID){
			markChatMessagesReaded(chatID);
		}
		setSelectedChat(chatID);
	}

	const markChatMessagesReaded = (chatID) => {

		const messagesReaded = activeChats[chatID].messages.filter(message => message.type === messageType.REQUEST && !message.readed);
		Promise.all(messagesReaded.map(message => setChatMessageReaded(message._id)))
		setActiveChats(prev => {
			return ({
				...prev,
				[chatID] : {
					...prev[chatID],
					pendent : 0
				}
			})
		})
	}

	const value = {
		loading,
		sendMessage,
		selectedChatID,
		markChatMessagesReaded,
		getSelectedChat,
		setSelectedChatID,
		getChatBySessionId,
		activeChats
	}

	return(
		<ChatContext.Provider value={value}>
			{children}
		</ChatContext.Provider>
	)
}