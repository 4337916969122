import { keyBy } from "lodash";
import { axios } from "../connection/ConnectionHandler";
import {
    SET_ESTABLISHMENT_INFO, SET_ESTABLISHMENT_DATA, SET_ESTABLISHMENT_SCHEDULED,
    SET_ESTABLISHMENT_ZONE, SET_ESTABLISHMENT_ACTIVITIES,
    SET_ESTABLISHMENT_SERVICES, SET_ESTABLISHMENT_TPVS,
    SET_ESTABLISHMENT_RESTAURANTS,
    SET_ESTABLISHMENT_MENUS,
    UPDATE_ESTABLISHMENT_DATA,
    SET_ESTABLISHMENT_HOMEPAGE
} from "../constants";
import { API } from "../constants/api";
import { MENU_OPTS } from "../constants/establishment";
import { fetchStaticTranslations } from "./staticTextActions";

export const loadEstablishmentInfo = () => {
    return async dispatch => {
        dispatch(fetchEstablishment());
        dispatch(fetchEstablishmentInfo());
        dispatch(fetchEstablishmentZones());
        dispatch(fetchEstablishmentActivities());
        dispatch(fetchEstablishmentScheduled());
        dispatch(fetchEstablishmentServices());
        dispatch(fetchEstablishmentRestaurants());
        dispatch(fetchEstablishmentMenus());
        dispatch(fetchEstablishmentHomePage());
        dispatch(fetchStaticTranslations());
    }
}

export const updateEstablishment = (data) => {
    return async dispatch => {
        axios.put(API.establishment.update, data)
        .then(response => {
            dispatch({type : UPDATE_ESTABLISHMENT_DATA, payload : response.data})
        })
        .catch(err => console.log(err))
    }
}

export const updateLogoBackgroundEstablishment = (data) => {
    return async dispatch => {
        axios.put(API.establishment.updateLogoBackground, data)
        .then(response => {
            dispatch({type : UPDATE_ESTABLISHMENT_DATA, payload : response.data})
        })
        .catch(err => console.log(err))
    }
}


// ESTABLISHMENT DATA
const fetchEstablishment = () => {
    return async dispatch => {
        axios.get(API.establishment.data)
        .then(response => {

            const { vw_info } = response.data;

            if(vw_info.hasVw){
                dispatch(fetchEstablishmentTpvs(vw_info.id));
            }
            dispatch({ type: SET_ESTABLISHMENT_DATA, payload: response.data })
            return response.data
        })
        .catch(err => console.log(err));
    };
};

// INFO
const fetchEstablishmentInfo = () => {
    return dispatch => {
        axios.get(API.establishment.info)
            .then(response => dispatch({ type: SET_ESTABLISHMENT_INFO, payload: response.data }))
            .catch(err => console.log(err));
    };
};

// ZONES
const fetchEstablishmentZones = () => {
    return dispatch => {
        axios.get(API.establishment.zone)
            .then(response => {
                const zones = keyBy(response.data, '_id');
                dispatch({ type: SET_ESTABLISHMENT_ZONE, payload: zones })
            })
            .catch(err => console.log(err));
    };
};

// ACTIVITIES
const fetchEstablishmentActivities = () => {
    return dispatch => {
        axios.get(API.establishment.activity)
            .then(response => dispatch({ type: SET_ESTABLISHMENT_ACTIVITIES, payload: keyBy(response.data, '_id') }))
            .catch(err => console.log(err));
    };
};

// SCHEDULED
const fetchEstablishmentScheduled = () => {
    return dispatch => {
        axios.get(API.establishment.scheduled)
            .then(response => dispatch({ type: SET_ESTABLISHMENT_SCHEDULED, payload: keyBy(response.data,'_id')}))
            .catch(err => console.log(err));
    };
};

// SERVICES
const fetchEstablishmentServices = () => {
    return dispatch => {
        axios.get(API.establishment.service)
            .then(response => dispatch({ type: SET_ESTABLISHMENT_SERVICES, payload: response.data }))
            .catch(err => console.log(err));
    };
};

// RESTAURANTS
const fetchEstablishmentRestaurants = () => {
    return dispatch => {
        axios.get(API.establishment.restaurant)
            .then(response => dispatch({type : SET_ESTABLISHMENT_RESTAURANTS, payload: keyBy(response.data,'_id')}))
            .catch(err => console.log(err));
    }   
}

// MENUS
const fetchEstablishmentMenus = () => {
    return dispatch => {
        axios.get(API.establishment.menu)
        .then(response => {
            const vw = {};
            const file = {};
            const data =  response.data;

            data.forEach(menu => {
                if(menu.type === MENU_OPTS.FILE){
                    file[menu._id] = menu;
                }else{ /* is VW , VW is indexed by tpv*/
                    vw[menu.tpv] = menu;
                }
            })
            
            dispatch({type : SET_ESTABLISHMENT_MENUS, payload : {vw, file}});
        })
        .catch(err => console.log(err))
    }
}

// TPVS
const fetchEstablishmentTpvs = (id) => {
    return async dispatch => {
        axios.get(API.vw.tpvs(id))
        .then(response => {
            const aux = response.data;
            let tpvs = {};
            aux.forEach(tpv => {
                tpvs[tpv.oid] = {
                    ...tpv,
                    mesa : null
                }
            })
            dispatch({type : SET_ESTABLISHMENT_TPVS, payload : tpvs})
        })
        .catch(err => console.log(err))
    }
} 

const fetchEstablishmentHomePage = () => {
    return dispatch => {
        axios.get(API.establishment.homepage)
        .then(response => {
            dispatch({type : SET_ESTABLISHMENT_HOMEPAGE, payload : response.data});
        })
        .catch(err => console.log(err))
    }
}