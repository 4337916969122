// https://testing-core.deploy.kuarasoftware.es/

export const VWLink = 'https://vw.kuarasoftware.es/';

export const API = {
  auth: {
    login: `/user/login`,
  },
  info: {
    create: '/info',
    update: '/info/',
  },
  service: {
    create: '/service',
    update: '/service/',
  },
  activity: {
    create: '/activity',
    update: '/activity/',
  },
  scheduled: {
    create: '/scheduled',
    update: '/scheduled/',
    delete: '/scheduled/',
  },
  page: {
    getAll: '/page',
    create: '/page',
    updateArray: (id) => `/page/${id}/updatearray`,
    addComp: (id) => `/page/${id}/addcomp`,
    updateComp: (pageId) => `/page/${pageId}/editcomp`,
    deleteComp: (pageId, compId, imageId = false) => {
      let url = `/page/${pageId}/${compId}/deletecomp`;

      if (imageId) {
        url += `/${imageId}`;
      }

      return url;
    },
  },
  homepage: {
    addComp: '/homepage/addcomp',
    updateComp: `/homepage/editcomp`,
    updateArray: `/homepage/updatearray`,
    deleteComp: (compId, imageId = false) => {
      let url = `/homepage/${compId}/deletecomp`;

      if (imageId) {
        url += `/${imageId}`;
      }

      return url;
    },
  },
  restaurant: {
    create: '/restaurant',
    update: '/restaurant/',
    addMenu: (restID, menuID) => `/restaurant/${restID}/${menuID}/add`,
    removeMenu: (restID, menuID) => `/restaurant/${restID}/${menuID}/remove`,
  },
  menu: {
    create: '/menu',
    update: (id) => `/menu/${id}`,
    delete: (id) => `/menu/${id}`,
  },
  vw: {
    tpvs: (id) => `https://apiwaiter.kuarasoftware.es/tpv/getall/${id}`,
  },
  establishment: {
    update: '/establishment',
    updateLogoBackground: '/establishment/images',

    homepage: '/homepage',
    data: '/establishment',
    info: '/info',
    zone: '/zone',
    activity: '/activity',
    scheduled: '/scheduled',
    service: '/service',
    gallery: '/establishment/gallery',
    restaurant: '/restaurant',
    menu: '/menu',
  },

  translations: {
    get: '/translation',
    update: (transId) => `/translation/${transId}`,
  },

  zone: {
    create: '/zone',
    update: '/zone/',
  },
  route: {
    all: `/routes`,
    create: `/routes`,
    update: '/routes/',
  },
  users: {
    profile: `/user/profile`,
    create: `/user/signup/`,
    edit: `/users/`,
    delete: `/user/`,
    get: `/user/`,
    list: `/users`,
    picture: `/user/picture`,
  },
  chat: {
    activechats: `/chat/active-chats`,
    inactivechats: '',
    sessionById: (sessionId) => `/chat?sessionId=${sessionId}`,
    markMessageReaded: (messageId) => `/chat/mark-readed?messageId=${messageId}`,
  },
};

export const MAP_API = {
  URL: 'https://api.mapbox.com/geocoding/v5/mapbox.places',
  TOKEN: 'pk.eyJ1Ijoia3VhcmFzb2Z0IiwiYSI6ImNrY3c1bHcxOTBhbncyenF2M283c2c5MzgifQ.qDxAkfUx8mSHDlIH4hW24w',
};
