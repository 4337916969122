import { API } from '../constants/api';
import { axios } from '../connection/ConnectionHandler';
import { SHOW_NOTIFICATION, ADD_PAGE, UPDATE_PAGE, SET_ALL_PAGES } from '../constants';
import { keyBy } from 'lodash';

export const fetchAllPages = () => {
    return async dispatch => {
        axios.get(API.page.getAll)
        .then(response => {
            dispatch({type : SET_ALL_PAGES, payload : keyBy(response.data, '_id')});
        })
        .catch(err => {
            console.log("🛠️ ~ file: pageActions.js ~ line 12 ~ createNewPage ~ err", err);
        })
    }
}

// adds a components to the page and index from indexs
export const addComponent = (id, comp, indexs, defaults, options) => {
    return async dispatch => {
        axios.put(API.page.addComp(id), {comp, indexs, defaults, options})
        .then(response => {
            dispatch({type : UPDATE_PAGE, payload : response.data})
        })
        .catch(err => {
            console.log("🚀 ~ file: pageActions.js ~ line 25 ~ addComponent ~ err", err)
            dispatch({type : SHOW_NOTIFICATION, payload : {show : true, status : 'error', message : 'Algo salió mal. Recarga la página'}})
        })
    }
}

// sets the value of the array of components from indexs 
export const updateComponentArray = (id, compList, indexs) => {
    return async dispatch => {
        axios.put(API.page.updateArray(id), {array : compList, indexs})
        .then(response => {
            dispatch({type : UPDATE_PAGE, payload : response.data})
        })
        .catch(err => {
            console.log("🚀 ~ file: pageActions.js ~ line 40 ~ updatePage ~ err", err)
            dispatch({type : SHOW_NOTIFICATION, payload : {show : true, status : 'error', message : 'Algo salió mal. Recarga la página'}})
        })
    }
}

export const updateComponent = (pageId, updatedProp,indexs, isImageProp = false) => {
    return async dispatch => {
        axios.put(API.page.updateComp(pageId), {prop : updatedProp, indexs, isImageProp})
        .then(response => {
            dispatch({type : UPDATE_PAGE, payload : response.data})
        })
        .catch(err => {
            console.log("🛠️ ~ file: pageActions.js ~ line 38 ~ updateComponent ~ err", err);
            dispatch({type : SHOW_NOTIFICATION, payload : {show : true, status : 'error', message : 'Algo salió mal. Recarga la página'}})
        })
    }
}

export const updateMultiPropsComponent = (pageId, updatedProps, indexs, imageKey) => {
    return async dispatch => {
        const propKeys = Object.keys(updatedProps);
        // we update secuentally each prop to avoid failures
        for(let i = 0; i < propKeys.length; i++){
            await axios.put(API.page.updateComp(pageId), {indexs, prop : {[propKeys[i]] : updatedProps[propKeys[i]]}, isImageProp : propKeys[i] === imageKey})
            .then(response => {
                // last prop dispatchs
                if(i === propKeys.length-1){
                    dispatch({type : UPDATE_PAGE, payload : response.data})
                }
            })
            .catch(err => {
                console.log("🚀 ~ file: pageActions.js ~ line 72 ~ updateMultiPropsComponent ~ err", err)
                dispatch({type : SHOW_NOTIFICATION, payload : {show : true, status : 'error', message : 'Algo salió mal. Recarga la página'}})
                return;
            })            
        }
    }
}


export const deleteComponent = (pageId, compId, indexs, imageId) => {
    return async dispatch => {
        axios.put(API.page.deleteComp(pageId,compId,imageId), {indexs})
        .then(response => {
            dispatch({type : UPDATE_PAGE, payload : response.data})
        })
        .catch(err => {
            console.log("🛠️ ~ file: pageActions.js ~ line 38 ~ deleteComponent ~ err", err);
            dispatch({type : SHOW_NOTIFICATION, payload : {show : true, status : 'error', message : 'Algo salió mal. Recarga la página'}})
        })
    }
}

export const deleteComponentNoDyspatch = async(pageId, compId, indexs, imageId) => {
    axios.put(API.page.deleteComp(pageId,compId,imageId), {indexs})
    .then(response => {
    })
    .catch(err => {
        console.log("🛠️ ~ file: pageActions.js ~ line 38 ~ deleteComponent ~ err", err);
    })
}

export const createNewPage = () => {
    return async dispatch => {
        const newPage = await axios.post(API.page.create)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                console.log("🛠️ ~ file: pageActions.js ~ line 12 ~ createNewPage ~ err", err);
                dispatch({type : SHOW_NOTIFICATION, payload : {show : true, status : 'error', message : 'Algo salió mal. Recarga la página'}})
            })
        dispatch({type : ADD_PAGE, payload : newPage})
        return newPage;
    }
}