import { keyBy } from "lodash";
import { axios } from "../connection/ConnectionHandler";
import { SET_TRANSLATIONS, UPDATE_TRANSLATION } from "../constants";
import { API } from "../constants/api";

export const fetchStaticTranslations = () => {
	return async dispatch => {
		return await axios.get(API.translations.get)
		.then(({data}) => {
			dispatch({type : SET_TRANSLATIONS, payload : keyBy(data, '_id')});
			return true;
		})
		.catch(err => {
    	console.log("🛠️ ~ file: staticTextActions.js ~ line 13 ~ fetchStaticTranslations ~ err", err);
			return false
		})
	}
}

export const updateTranslation = (id, lng, text) => {
	return async dispatch =>{
		return await axios.put(API.translations.update(id), {language : lng, text})
		.then(({data}) => {
			dispatch({type : UPDATE_TRANSLATION, payload : {id : data._id, data}})
		})
		.catch(err => {
    	console.log("🛠️ ~ file: staticTextActions.js ~ line 13 ~ fetchStaticTranslations ~ err", err);
			return false
		})
	}
}