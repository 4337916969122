import { axios } from '../connection/ConnectionHandler';
import {
    APP_COOKIE_NAME, AUTH, PASS_COOKIE_NAME,
    SHOW_NOTIFICATION, SET_PROFILE,
    USER_COOKIE_NAME, USER_ID_COOKIE_NAME
} from "../constants";
import { API } from "../constants/api";
import { deleteCookie, getCookie, setCookie } from "../utils";
import { fetchAllRoutes } from './../actions/routeAction'
import { loadEstablishmentInfo } from './establishmentActions';
import { fetchAllPages } from './pageActions';

const rememberLogin = (username, password, remember) => {
    if (remember) {
        setCookie(USER_COOKIE_NAME, username, 30);
        setCookie(PASS_COOKIE_NAME, password, 30);
    } else {
        deleteCookie(USER_COOKIE_NAME, '');
        deleteCookie(PASS_COOKIE_NAME, '');
    };
};


/* PERSISTENCY CHECK */
export const checkToken = () => {
    return async dispatch => {
        const token = await getCookie(APP_COOKIE_NAME);
        const userId = await getCookie(USER_ID_COOKIE_NAME);
        if (token) {
            dispatch({ type: AUTH, payload: { auth: true, token, userId } })
        };
    }
};


/* INITAL DATA LOAD */
export const loadInitialData = () => {
    return async dispatch => {
        dispatch(loadEstablishmentInfo())
        dispatch(fetchAllPages());
        dispatch(fetchAllRoutes());
        dispatch(fetchUserProfile());
    };
};

export const fetchUserProfile = () => {
    return dispatch => {
        axios.get(API.users.profile)
            .then(response =>
                dispatch({ type: SET_PROFILE, payload: response.data }))
            .catch(err => err);
    }
};

export const setPicture = (doc) => {
    return async dispatch => {
        const response = await axios.post(API.users.picture, doc)
            .then(response => {
                dispatch({ type: SET_PROFILE, payload: response.data })
                dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'info', message: 'Imagen guardada.' } });
            }).catch(err => err);
        return response;
    }
};

export const login = (username, password, remember) => {
    return async dispatch => {
        const response = await axios.post(API.auth.login, { username, password })
            .then(response => {
                const { access_token } = response.data || {};
                rememberLogin(username, password, remember);
                dispatch({ type: AUTH, payload: { auth: true, token: access_token } });
                return response;
            }).catch(err => err)
        return response;
    }
};
