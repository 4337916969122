import { keyBy } from 'lodash';
import { API } from '../constants/api';
import { SET_ALL_ROUTES, SET_CURRENT_ROUTE, SHOW_NOTIFICATION, UPDATE_ROUTE, ADD_ROUTE } from '../constants';
import { axios } from '../connection/ConnectionHandler';

export const fetchAllRoutes = () => {
    return async dispatch => {
        const response = await
            axios.get(API.route.all).then(response => {
                const routes = keyBy(response.data, 'keyapp');

                const first = Object.keys(routes)[0]

                dispatch({ type: SET_ALL_ROUTES, payload: routes});
                dispatch({ type : SET_CURRENT_ROUTE, payload : routes[first]})
                return 200;
            }).catch(err => {
                console.log(err)
                dispatch({ type: SET_ALL_ROUTES, payload: {} });
                return err
            });
        return response;
    };
};

export const updateAllRoutes = (routesArray) => {
    return async dispatch => {
        Promise.all(routesArray.map(route => 
            axios.put(`${API.route.update}${route._id}`, route)
            .then(response => response.data)
            .catch(err => {
                console.log("🛠️ ~ file: routeAction.js ~ line 42 ~ updateAllRoutes ~ err", err);
            })
        ))
        .then(responses => {
            const routes = keyBy(responses, 'keyapp');
            dispatch({ type: SET_ALL_ROUTES, payload: routes});
        })
        .catch(err => {
            console.log("🛠️ ~ file: routeAction.js ~ line 42 ~ updateAllRoutes ~ err", err);
            dispatch({type : SHOW_NOTIFICATION, payload : {show : true, status : 'error', message : 'Algo salió mal. Recarga la página'}})
        })
    }
}

export const updateRoute = data => {
    return async dispatch => {
        const response = await axios.put(`${API.route.update}${data._id}`, data)
            .then(response => {
                dispatch({ type: UPDATE_ROUTE, payload: response.data });
                dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'info', message: 'Página modificada.' } });
                return response.status;
            })
            .catch(err => {
                console.log(err)
                return err;
            });
        return response;
    }
};

export const createRoute = (pageID,titles)  => {

    const keyName = Object.values(titles)[0]?.toLowerCase().replace(/\s/g, '_');

    const data = {
        order : 99,
        showInMenu: true,
        showHeader: true,
        showSearch: false,
        role : [
            "97f567cf",
            "a2470ee4",
            "8fc02de6",
            "0d4bdfd5",
            "b8eaa583"
        ],
        title : titles,
        keyapp : keyName,
        slug : keyName,
        to : `/custom/${pageID}`
    }

    return async dispatch => {
        await axios.post(API.route.create, data)
        .then(response => {
            dispatch({type : ADD_ROUTE, payload : response.data})
            dispatch({type : SHOW_NOTIFICATION, payload : {show : true, status : 'success', message : 'Página creada con éxito'}})
        })
        .catch(err => {
            dispatch({type : SHOW_NOTIFICATION, payload : {show : true, status : 'error', message : 'Algo salió mal. Recarga la página'}})
        })
    }
}