import { combineReducers } from 'redux';
import {
    authorization, currentRoute, notifications, routes, app, establishment, menus,restaurants,
    usersForm, profile, info, services, scheduled, activities, zones, actions, pages,tpvs,homepage,
    translations,
} from "./Reducers";

export default combineReducers({
    app,
    actions,
    info,
    services, 
    scheduled,
    activities,
    translations,
    homepage,
    menus,
    tpvs,
    restaurants,
    zones,
    authorization,
    currentRoute,
    profile,
    notifications,
    routes,
    pages,
    establishment,
    usersForm
});