import { handleActions } from 'redux-actions';
import {
    AUTH, SET_CURRENT_ROUTE, LOGOUT, SET_PROFILE,
    SHOW_NOTIFICATION, CLOSE_NOTIFICATION,
    SET_ALL_ROUTES, UPDATE_ROUTE, SET_SIDE_MENU_STATE, OPEN_USERS_FORM, CLOSE_USERS_FORM, EDIT_SELECTED_USER, USERS_FORM_TOOGLE_LOADING, SET_USER_LIST, 
    ADD_NEW_USER, UPDATE_USER, SET_ESTABLISHMENT_COLOR,
    // ESTABLISHMENT
    SET_ESTABLISHMENT_INFO, SET_ESTABLISHMENT_DATA, SET_ESTABLISHMENT_ACTIVITIES,
    SET_ESTABLISHMENT_SCHEDULED, SET_ESTABLISHMENT_SERVICES, SET_ESTABLISHMENT_ZONE, SET_ESTABLISHMENT_GALLERY, ADD_GALLERY_IMAGES,
    SET_ACTIVITIES_LIST_DRAWER, SET_SHOW_NEW_PAGE_FORM,
    ADD_SCH_ACTIVITY, DELETE_SERVICES_BY_ZONE, DELETE_SCH_ACTIVITY_BY_ZONE,
    UPDATE_SCH_ACTIVITY, SET_ESTABLISHMENT_RESTAURANTS,
    DELETE_SCH_ACTIVITY,
    SET_ESTABLISHMENT_MENUS,
    ADD_RESTAURANT,
    ADD_FILE_MENU,UPDATE_FILE_MENU,
    ADD_VW_MENU,
    UPDATE_RESTAURANTS,
    SET_ALL_PAGES,
    UPDATE_PAGE,
    ADD_ROUTE,
    ADD_PAGE,
    SHOW_NEW_PAGE_FORM,
    SET_ESTABLISHMENT_TPVS,
    DELETE_VW_MENU,
    UPDATE_ESTABLISHMENT_DATA,
    DELETE_FILE_MENU,
    SET_ESTABLISHMENT_HOMEPAGE,
    UPDATE_HOMEPAGE,
    SET_TRANSLATIONS, UPDATE_TRANSLATION
} from './../../constants';
import { cloneDeep, unionBy } from 'lodash';


/* BASIC */
export const authorization = handleActions({
    [AUTH]: (state, action) => action.payload,
    [LOGOUT]: () => ({ auth: false })
}, null);


/* PROFILE */
export const profile = handleActions({
    [SET_PROFILE]: (state, action) => action.payload,
}, null);

/* STATIC TEXT TRANSLATIONS */
export const translations = handleActions({
    [SET_TRANSLATIONS] : (state, action) => action.payload,
    [UPDATE_TRANSLATION] : (state, action) => {
        const newState = cloneDeep(state);
        const { id, data } = action.payload;
        newState[id] = data;
        return newState;
    }
}, null)

/* ESTABLISHMENT */
export const establishment = handleActions({
    [SET_ESTABLISHMENT_DATA]: (s, action) => action.payload,
    [SET_ESTABLISHMENT_COLOR] : (s, action) => {
        const newState = {...s};
        newState.oldC = s.oldC ? s.oldC : s.color;
        newState.color = action.payload;
        return newState;
    },
    [UPDATE_ESTABLISHMENT_DATA] : (s, action) => {
        // override keys
        return {...s, ...action.payload}
    },
    [SET_ESTABLISHMENT_GALLERY] : (s, action) => {
        const newState = {...s};
        newState.gallery = action.payload;
        return newState;
    },
    [ADD_GALLERY_IMAGES] : (s, action) => {
        const newState = {...s};
        newState.gallery = newState.gallery.concat(action.payload).sort((imgA, imgB) => imgA.order - imgB.order);
        return newState;
    }
}, null)

export const homepage = handleActions({
    [SET_ESTABLISHMENT_HOMEPAGE] : (s, action) => action.payload,
    [UPDATE_HOMEPAGE] :  (s, action) => action.payload
}, null)

export const info = handleActions({
    [SET_ESTABLISHMENT_INFO]: (state, action) => action.payload,
},[])

export const zones = handleActions({
    [SET_ESTABLISHMENT_ZONE] : (state, action) => action.payload,
}, {})
export const activities = handleActions({
    [SET_ESTABLISHMENT_ACTIVITIES] : (state, action) => action.payload,
}, {})
export const scheduled = handleActions({
    [SET_ESTABLISHMENT_SCHEDULED] : (state, action) => action.payload,
    [ADD_SCH_ACTIVITY] : (state, action) => {
        const { _id } = action.payload;
        return ({ ...state, [_id]: action.payload });
    },
    [UPDATE_SCH_ACTIVITY] : (state, action) => {
        const { _id } = action.payload;
        return ({ ...state, [_id]: action.payload });
    },
    [DELETE_SCH_ACTIVITY] : (state, action) => {
        const newState = {...state};
        delete newState[action.payload];
        return newState;
    },
    [DELETE_SCH_ACTIVITY_BY_ZONE] : (state, action) => {
        const newState = cloneDeep(state);
        const deletedZones = action.payload;
        Object.values(state).forEach(sch => {
            if(deletedZones.includes(sch.zone)){
                delete newState[sch._id];
            }
        })
        return newState;
    }
}, {})
export const services = handleActions({
    [SET_ESTABLISHMENT_SERVICES] : (state, action) => action.payload,
    [DELETE_SERVICES_BY_ZONE] : (state, action) => {
        const newState = cloneDeep(state);
        const deletedZones = action.payload;
        let deleteCount = 0;
        state.forEach((service, index) => {
            if(deletedZones.includes(service.zone)){
                newState.splice(index - deleteCount,1);
                deleteCount++;
            }
        })
        return newState;
    }
}, [])

export const restaurants = handleActions({
    [SET_ESTABLISHMENT_RESTAURANTS] : (state, action) => action.payload,
    [ADD_RESTAURANT] : (state, action) => {
        const { _id } = action.payload
        return ({...state, [_id] : action.payload})
    },
    [UPDATE_RESTAURANTS] : (state,action) => ({...state, ...action.payload})

},{})

export const tpvs = handleActions({
    [SET_ESTABLISHMENT_TPVS] : (state, action) => action.payload,
},{})

export const menus = handleActions({
    [SET_ESTABLISHMENT_MENUS] : (s, action) => action.payload,
    [ADD_FILE_MENU] : (state, action) => {
        const { _id } = action.payload
        return {vw : {...state.vw}, file : {...state.file, [_id] : action.payload}};
    },
    [UPDATE_FILE_MENU] :(state, action) => {
        const { _id } = action.payload
        return {vw : {...state.vw}, file : {...state.file, [_id] : action.payload}};
    },
    [ADD_VW_MENU] : (state, action) => {
        const { tpv } = action.payload
        return {file : {...state.file}, vw : {...state.vw, [tpv] : action.payload}};
    },
    [DELETE_VW_MENU] : (state,action) => {
        const nState = cloneDeep(state)
        delete nState.vw[action.payload]
        return nState;
    },
    [DELETE_FILE_MENU] : (state, action) => {
        const nState = cloneDeep(state)
        delete nState.file[action.payload]
        return nState;
    }
},{file : {}, vw : {}})

/* APP */
export const currentRoute = handleActions({
    [SET_CURRENT_ROUTE] : (s, action) => action.payload
}, null);

export const notifications = handleActions({
    [SHOW_NOTIFICATION]: (state, action) => action.payload,
    [CLOSE_NOTIFICATION]: (state, action) => ({ show: false, status: null, message: null })
}, { show: false, status: null, message: null });

export const routes = handleActions({
    [SET_ALL_ROUTES]: (state, action) => action.payload,
    [UPDATE_ROUTE]: (state, action) => {
        const { keyapp } = action.payload;
        return ({ ...state, [keyapp]: action.payload });
    },
    [ADD_ROUTE] : (state, action) => {
        const { keyapp } = action.payload;
        return ({ ...state, [keyapp]: action.payload });
    }
}, null);

export const pages = handleActions({
    [SET_ALL_PAGES] : (state, action) => action.payload,
    [ADD_PAGE] : (state,action) => {
        const { _id } = action.payload;
        return ({...state, [_id] : action.payload})
    },
    [UPDATE_PAGE] : (state, action) => {
        const { _id } = action.payload;
        return ({...state, [_id] : action.payload})
    }
},null)

/* APP */
export const app = handleActions({
    [SET_SIDE_MENU_STATE]: (state, action) => {
        return ({ ...state, isCollapsedSideMenu: action.payload });
    }
}, { isCollapsedSideMenu: true });

export const actions = handleActions({
    [SET_ACTIVITIES_LIST_DRAWER] : (state, action) => {
        return ({...state, activitiesList : action.payload});
    },
    [SHOW_NEW_PAGE_FORM] : (state, action) => ({...state, showNewPageForm : true }),
    [SET_SHOW_NEW_PAGE_FORM] : (state, action) => {
        return ({...state, showNewPageForm : action.payload })}
},{activitiesList: false, confirm_act_delete : true, showNewPageForm : false})

export const users = handleActions({
    [SET_USER_LIST]: (state, action) => action.payload,
    [ADD_NEW_USER]: (state, action) => [...state.users, action.payload],
    [UPDATE_USER]: (state, action) => {
        return unionBy(
            [action.payload],
            state || [],
            'value'
        );
    }
}, null);

export const usersForm = handleActions({
    [OPEN_USERS_FORM]: (state) => ({ show: !state.show, data: null, loading: false }),
    [CLOSE_USERS_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_USER]: (s, action) => ({ show: true, data: action.payload, loading: false }),
    [USERS_FORM_TOOGLE_LOADING]: (state) => ({ ...state, loading: !state.loading })
}, { show: false, data: null, loading: false });