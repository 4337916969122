import { cloneDeep } from "lodash"

export const setTranslatable = (data, [...translatableKeys]) => {
    const translatableData = cloneDeep(data);
    translatableKeys.forEach(key => {
        if(translatableData[key]){
            translatableData[key].isTranslatable = true
        }
    })

    return translatableData
}

export const translateStaticText = (staticTexts, text, lng) => {
    if(staticTexts[text] && staticTexts[text].translations[lng]){
        return staticTexts[text].translations[lng]
    }

    return text;
}

export const translateProp = (prop, lng, show = true) => {
    if(prop.isTranslatable && prop[lng]){
        return prop[lng]
    }
    
    if(show){
        return 'No traducido'
    }

    return null;
}
