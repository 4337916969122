import { createSelector } from 'reselect';
import { nonEditableRoutes, ROLES_RAW } from '../../constants/permissions';

/* APP */
export const isUserAuthorized = createSelector(state => state.authorization, authorization => authorization);
export const getCurrentRoute = createSelector(state => state.currentRoute, currentRoute => currentRoute);
export const getCurrentRouteTitle = createSelector(getCurrentRoute, route => route.title);
export const getSideMenuState = createSelector(state => state.app, app => app.isCollapsedSideMenu);
export const getCurrentReportDateFilter = createSelector(state => state.app, app => app?.reportSharedData?.currentDateFilter);

/* ACTIONS */
export const getShowActList = createSelector(state => state.actions, actions => actions.activitiesList);
export const getShowNewPageForm = createSelector(state => state.actions, actions => actions.showNewPageForm);

/* PROFILE */
export const getUserProfile = createSelector(state => state.profile, profile => profile);
export const currentUserId = createSelector(state => state.profile, profile => profile && profile.id);
export const getCurrentUserRole = createSelector(getUserProfile, profile => ROLES_RAW.includes(profile?.role) ? profile?.role : null);
export const getCurrentUserEstablishmentId = createSelector(getUserProfile, profile => profile?.establishment);

/* TRANSLATIONS */
export const getTranslations = createSelector(state => state.translations, translations => translations);

/* ESTABLISHMENT */
export const getEstablishment = createSelector(state => state.establishment, establishment => establishment ? establishment : {});
export const getEstablishmentLng = createSelector(getEstablishment, establishment => establishment?.language);
export const getEstablishmentSettings = createSelector(getEstablishment, establishment => establishment?.settings);
export const getEstablishmentColor = createSelector(getEstablishment, establishment => establishment?.color);
export const getEstablishmentLogo = createSelector(getEstablishment, establishment => establishment?.logo?.url);
export const getEstablishmentBg = createSelector(getEstablishment, establishment => establishment?.background?.url);
export const getEstablishmentId = createSelector(getEstablishment, establishment => establishment?._id);
export const getEstablishmentGallery = createSelector(getEstablishment, establishment => establishment?.gallery);
export const getEstablishmentMap = createSelector(getEstablishment, establishment => establishment?.map);

export const getVWInfo = createSelector(getEstablishment, establishment => establishment?.vw_info);

export const getRestaurants = createSelector(state => state.restaurants, restaurants => restaurants);
export const getInfo = createSelector(state => state.info, info => info);
export const getServices = createSelector(state => state.services, services => services);
export const getZones = createSelector(state => state.zones, zones => zones);
export const getActivities = createSelector(state => state.activities, activities => activities);
export const getScheduled = createSelector(state => state.scheduled, scheduled => scheduled);
export const getVWMenus = createSelector(state => state.menus, menus => menus.vw);
export const getFileMenus = createSelector(state => state.menus, menus => menus.file);
export const getVWTPVs = createSelector(state => state.tpvs, tpvs => tpvs);
export const getVWTPVbyId = id => createSelector(getVWTPVs, tpvs => tpvs ? tpvs[id] : null);

export const getZoneById = id => createSelector(getZones, zones => zones ? zones[id] : undefined);
/* NOTIFICATIONS STATE */
export const getNotificationsState = createSelector(state => state.notifications, notifications => notifications);

/* USERS */
export const getUsers = createSelector(state => state.users, users => users);
/* USERS */

/* ROUTES */
export const getRoutes = createSelector(state => state.routes, routes => routes ? routes : {});
export const getRouteByKeyApp = keyapp => createSelector(getRoutes, routes => routes ? routes[keyapp] : null);

export const getNonEditableRoutes = createSelector(getRoutes, routes => {
    if(routes){
        let editableRoutes = {};
        Object.keys(routes).forEach(key => {
            if(!nonEditableRoutes[key]){
                editableRoutes[key] = routes[key];
            }
        })


    
        return editableRoutes
    }else{
        return null;
    }
    
})

export const getPageByID = id => createSelector(state => state.pages, pages => pages ? pages[id] : null);
// only for first level components
export const getComponentByIndex = (pageId, index) => createSelector(getPageByID(pageId), page => page.components[index]);
 
/* USERS FORM */
export const getUsersForm = createSelector(state => state.usersForm, form => form);