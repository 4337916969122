import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getEstablishmentLng } from "../store/selectors";
import countriesLng from '../assets/countries_lng';
import { useContext } from "react";
import { useEffect } from "react";
import { translateProp } from "./utils";

const LngContext = React.createContext();

export const useLng = () => {
    const context = useContext(LngContext);

    return context
}

export const LngProvider = ({children}) => {
    const defaultLanguage = window.localStorage.getItem('vdd-lang');
    const lng = useSelector(getEstablishmentLng);
    const [userLng, setULng] = useState(defaultLanguage);
    // const translations = useSelector(getTranslations);

    useEffect(() => {
        if(lng && lng.length > 0 && !userLng){
            setULng(lng[0]);
        }
    },[lng, userLng])

    const value = {
        userLng,
        onChangeLng : selected => {
            const index = countriesLng.findIndex(c => c.code === selected);
            const newLng = index !== -1  ? selected : lng[0]
            setULng(newLng);
            window.localStorage.setItem('vdd-lang', newLng);
        },
        t : (prop, show = true) => translateProp(prop, userLng, show),
        // tstatic : (text) => translateStaticText(translations, text, userLng),
    }

    return (
        <LngContext.Provider value={value}>
            {children}
        </LngContext.Provider>
    )
}
